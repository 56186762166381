import { FileStatus, FileSubmittedBy } from 'services/files/types';

// Action types
export enum OrganizationsActionTypes {
  SET_ORGANIZATIONS = 'eb.organizations.SET_ORGANIZATIONS',
  SET_PAGE = 'eb.organizations.SET_PAGE',
  SET_PAGE_SIZE = 'eb.organizations.SET_PAGE_SIZE',
  SET_ORGANIZATION_SELECTED = 'eb.organizations.SET_ORGANIZATION_SELECTED',
  RESET_PARAMS = 'eb.organizations.RESET_PARAMS',
  SET_ORGANIZATION_DETAILS_SELECTED = 'eb.organizations.SET_ORGANIZATION_DETAILS_SELECTED',
  SET_SEARCH = 'eb.organizations.SET_SEARCH'
}

// Generica Organizations actions
export interface OrganizationsAction<P> {
  type: OrganizationsActionTypes;
  data: P;
}

export interface OrganizationsSetOrganizationssAction
  extends OrganizationsAction<DoGetOrganizationsResp> {
  type: OrganizationsActionTypes.SET_ORGANIZATIONS;
}

export interface OrganizationsSetOrganizationDetailsAction
  extends OrganizationsAction<DoGetOrganizationDetailsResp> {
  type: OrganizationsActionTypes.SET_ORGANIZATION_DETAILS_SELECTED;
}

export interface OrganizationsSetPageAction extends OrganizationsAction<number> {
  type: OrganizationsActionTypes.SET_PAGE;
}

export interface OrganizationsSetPageSizeAction extends OrganizationsAction<number> {
  type: OrganizationsActionTypes.SET_PAGE_SIZE;
}

export interface OrganizationsSetSearchAction extends OrganizationsAction<string> {
  type: OrganizationsActionTypes.SET_SEARCH;
}

export interface OrganizationsResetParamsSizeAction extends OrganizationsAction<null> {
  type: OrganizationsActionTypes.RESET_PARAMS;
}

export interface OrganizationSetOrganizationActiveAction extends OrganizationsAction<string> {
  type: OrganizationsActionTypes.SET_ORGANIZATION_SELECTED;
}

export type OrganizationsAllActions =
  | OrganizationsSetOrganizationssAction
  | OrganizationsSetPageAction
  | OrganizationsSetPageSizeAction
  | OrganizationSetOrganizationActiveAction
  | OrganizationsResetParamsSizeAction
  | OrganizationsSetOrganizationDetailsAction
  | OrganizationsSetSearchAction;

export interface OrganizationsParams {
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
  search?: string;
}

export interface OrganizationsState {
  companyCodes: Record<string, string[]>;
  list: Record<string, Company>;
  params: OrganizationsParams;
  selected: string;
  selectedOrganizationDetails: Company;
}

export interface Program {
  billingSource: string;
  enrollmentType: string;
  programTag: string;
}

export interface Org {
  code: string;
  name: string;
  isProd?: boolean;
  alternateName?: string;
  validationField?: string;
  suppressPortabilityEmails?: boolean;
  suppressTerminationEmails?: boolean;
}

interface CompanyFile {
  fileId: string;
  failureCount: number;
  fileName: string;
  fileStatus: FileStatus | null;
  uploadedAt: string;
  successCount: number;
  updatedCount: number;
  submittedBy?: FileSubmittedBy;
}

export interface Company {
  files?: CompanyFile[];
  programs: Program[];
  org: Org;
}

// API interfaces
export interface DoGetOrganizationsResp {
  companies: Company[];
  page: number;
  pageSize: number;
  total: number;
  totalPages: number;
}

export type DoGetOrganizationDetailsResp = Company;
