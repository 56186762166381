// Globals
import './styles.scss';
import 'theme/basel-grotesk/styles.scss'; // Font styles
import React from 'react';

import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import config from 'config';

// Components
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { Router } from 'components/Router';

// Misc
import store from './store';
import { routes } from './routes';

// @ts-ignore
window.process = require('process');

Sentry.init({
  dsn: config.sentry_dsn,
  environment: config.env,
  enabled: config.env !== 'dev',
  ignoreTransactions: [/^\/reset-password$/],
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    })
  ],
  tracesSampleRate: 1
});

const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Router routes={routes} />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('app')
);
