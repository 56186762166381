import * as endpoints from 'endpoints';
import { ApiDomain } from 'services/api/types';
import { DoGetAdminUsersResp, InviteUser } from './types';
import { eb } from 'services/api/async';
import { EbThunk } from 'store/types';
import { getOrganizationsSelected } from 'services/organizations/selectors';
import { setAdminUsers } from './reducers';

doGetAdminUsers.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.USERS,
  method: 'GET'
};

doInviteUsers.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.USERS,
  method: 'POST'
};

doRemoveUser.data = {
  domain: ApiDomain.EB,
  endpoint: endpoints.ADMIN_USER,
  method: 'DELETE'
};

export function doGetAdminUsers(): EbThunk<Promise<void>> {
  return async (dispatch, getState) => {
    // Request
    const {
      data: { endpoint, method }
    } = doGetAdminUsers;

    const companyCodeSelected = getOrganizationsSelected(getState());

    const params = companyCodeSelected
      ? { companyCode: companyCodeSelected, page: 0, pageSize: 100 }
      : { page: 0, pageSize: 100 };

    const usersResp: DoGetAdminUsersResp = await dispatch(
      eb<DoGetAdminUsersResp>({
        endpoint,
        method,
        params
      })
    );

    dispatch(setAdminUsers(usersResp));
  };
}

export function doInviteUsers(userDetails: InviteUser): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doInviteUsers;
    const data = userDetails.role === 'developer' ? {
      ...userDetails
    } :
    {
      ...userDetails,
      uploadMethod: 'portal'
    };

    await dispatch(
      eb({
        endpoint,
        method,
        data
      })
    );
  };
}

export function doRemoveUser(userId: string): EbThunk<Promise<void>> {
  return async (dispatch) => {
    const {
      data: { endpoint, method }
    } = doRemoveUser;

    await dispatch(
      eb({
        endpoint,
        method,
        pathParams: [userId]
      })
    );
  };
}
