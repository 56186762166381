import {
  GetFileDetailsResponse,
  FileDetailsActionTypes,
  FileDetailsAllAction,
  FileRecordsState,
  FileDetailsState,
  FileSummary
} from './types';
import { initialParams, initialFileState, initialRecordsState } from './selectors';
import { combineReducers } from '@reduxjs/toolkit';

export const setFileDetails = (data: GetFileDetailsResponse, companyCode: string) => ({
  type: FileDetailsActionTypes.SET_FILE_DETAILS,
  data,
  companyCode
});

export const setCount = (data: number) => ({ type: FileDetailsActionTypes.SET_COUNT, data });

export const setPageSize = (data: number) => ({
  type: FileDetailsActionTypes.SET_PAGE_SIZE,
  data
});

export const setPage = (data: number) => ({
  type: FileDetailsActionTypes.SET_PAGE,
  data
});

export const setSortField = (data: string) => ({
  type: FileDetailsActionTypes.SET_SORT_FIELD,
  data
});

export const setSortOrder = (data: 1 | -1) => ({
  type: FileDetailsActionTypes.SET_SORT_ORDER,
  data
});

export const resetFilters = () => ({
  type: FileDetailsActionTypes.RESET_FILTERS
});

function records(
  fileRecords: FileRecordsState = initialRecordsState,
  action: FileDetailsAllAction
): FileRecordsState {
  switch (action.type) {
    case FileDetailsActionTypes.SET_FILE_DETAILS: {
      const {
        data: {
          records,
          page,
          pageSize,
          total,
          totalPages,
          acceptedRecordCount = 0,
          rejectedRecordCount = 0,
          noActionRecordCount = 0,
          updatedRecordCount = 0
        }
      } = action;

      return {
        ...fileRecords,
        list: records,
        acceptedRecordCount,
        rejectedRecordCount,
        noActionRecordCount,
        updatedRecordCount,
        params: { ...(fileRecords?.params || initialParams), page, pageSize, total, totalPages }
      };
    }
    case FileDetailsActionTypes.SET_PAGE: {
      const page = action.data;
      return {
        ...fileRecords,
        params: {
          ...(fileRecords?.params || initialParams),
          page
        }
      };
    }
    case FileDetailsActionTypes.SET_PAGE_SIZE: {
      const pageSize = action.data;
      return {
        ...fileRecords,
        params: {
          ...(fileRecords?.params || initialParams),
          page: 0,
          pageSize
        }
      };
    }
    case FileDetailsActionTypes.SET_SORT_FIELD: {
      const sortField = action.data;
      return {
        ...fileRecords,
        params: {
          ...(fileRecords?.params || initialParams),
          page: 0,
          sortField
        }
      };
    }
    case FileDetailsActionTypes.SET_SORT_ORDER: {
      const sortOrder = action.data;
      return {
        ...fileRecords,
        params: {
          ...(fileRecords?.params || initialParams),
          page: 0,
          sortOrder
        }
      };
    }
    case FileDetailsActionTypes.RESET_FILTERS: {
      return {
        ...fileRecords,
        params: initialParams
      };
    }
    default:
      return fileRecords;
  }
}

const summary = (file: FileSummary = initialFileState, action: FileDetailsAllAction) => {
  switch (action.type) {
    case FileDetailsActionTypes.SET_FILE_DETAILS: {
      return action.data?.summary || initialFileState;
    }
    default:
      return file;
  }
};

export const fileDetails = combineReducers<FileDetailsState>({
  records,
  summary
});
