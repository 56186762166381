// Misc
import format from 'date-fns/format';
import { getMemberDisplayName } from 'utils/statuses';

// Types
import { CompanyPeopleRecord, SubscriptionStatus } from 'services/people/types';
import { FilterOption } from 'components/Filter/types';
import { TableColumn } from 'components/Table/types';
import { Anchor } from 'components/Anchor';

export const PEOPLE_COLUMNS: TableColumn<CompanyPeopleRecord>[] = [
  {
    title: 'Company User ID',
    dataIndex: 'companyUserId',
    width: 136,
    render: (_, { companyCode, companyUserId, userId }) =>
      userId ? (
        <Anchor className="eb-people-record-id" href={`/${companyCode}/people/${userId}`}>
          {companyUserId}
        </Anchor>
      ) : (
        companyUserId
      )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    render: (_, { companyCode, name, userId }) => {
      const displayName = name
        ? `${name.first ?? ''}${name.middle ? ` ${name.middle}` : ''} ${name.last ?? ''}`
        : '—';
      return userId ? (
        <Anchor className="eb-people-record-id" href={`/${companyCode}/people/${userId}`}>
          {displayName}
        </Anchor>
      ) : (
        displayName
      );
    }
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan',
    render: (_, { planTitle }) => `${planTitle ?? '—'}`
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    onCell: () => ({ className: 'eb-people-record-status' }),
    render: (_, { status, scheduledEffectiveDate, scheduledChangeType }) =>
      getMemberDisplayName(status, { scheduledEffectiveDate, scheduledChangeType }) ?? '—'
  },
  {
    title: 'Changed',
    dataIndex: 'updated',
    key: 'updated',
    width: 100,
    render: (_, { updatedAt }) => (
      <div className="eb-people-record-date">
        {updatedAt ? format(new Date(updatedAt), 'MMM dd, yyyy') : '—'}
      </div>
    )
  }
];

const FILTER_STATUSES = Object.values(SubscriptionStatus)
  .map((key) => ({
    label: getMemberDisplayName(key, {}),
    key:
      key === SubscriptionStatus.PROVISIONING
        ? `${SubscriptionStatus.PROVISIONING},${SubscriptionStatus.PENDING}`
        : key
  }))
  // Remove duplicate filter item - We display "Provisioning" for both SubscriptionStatus.PROVISIONING and SubscriptionStatus.PENDING
  .filter(
    (item) => item.key !== SubscriptionStatus.NULL && item.key !== SubscriptionStatus.PENDING
  );

const FILTER_PLANS = [
  { label: 'Essential Individual', key: 'ebessind' },
  { label: 'Essential Family', key: 'ebessfam' },
  { label: 'DigitalGuard Individual', key: 'dgind' },
  { label: 'DigitalGuard Family', key: 'dgfam' },
  { label: 'Premium Individual', key: 'premind' },
  { label: 'Premium Family', key: 'premfam' },
  { label: 'DigitalGuard Complete Individual', key: 'dgcompind' },
  { label: 'DigitalGuard Complete Family', key: 'dgcompfam' },
  { label: 'Protected Individual', key: 'protectind' },
  { label: 'Protected Family', key: 'protectfam' },
  { label: 'Protected+ Individual', key: 'protectplusind' },
  { label: 'Protected+ Family', key: 'protectplusfam' },
  { label: 'Ultimate Executive', key: 'ebultfamexec' }
];

const SCHEDULED_CHANGE_TYPE = [
  { label: 'Scheduled add', key: 'ADD' },
  { label: 'Scheduled term', key: 'TERMINATE' },
  { label: 'Scheduled change', key: 'CHANGE' }
];

export const FILTER_OPTIONS: FilterOption[] = [
  {
    key: 'status',
    label: 'By status',
    values: [{ label: 'All', key: '' }, ...FILTER_STATUSES],
    type: 'choice',
    isMulti: true
  },
  {
    key: 'plan',
    label: 'By plan',
    values: FILTER_PLANS,
    type: 'tag'
  },
  {
    key: 'scheduledChangeType',
    label: 'By scheduled change type',
    values: SCHEDULED_CHANGE_TYPE,
    type: 'choice',
    isMulti: false
  }
];
