// Global
import './styles.scss';
import React from 'react';

// Components
import { Dot } from 'components/Dot';
import { FileListItem } from 'components/FileListItem';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { FileUploadType, NoFiles } from 'components/NoFiles';
import { TableList } from 'components/Table/List';

// Services
import { doListFiles } from 'services/files/async';
import {
  getFilesList, getFilesParams,
  getFilesParamsPage,
  getFilesParamsPageSize,
  getFilesParamsTotal,
  getFilesParamsTotalPages,
  getFilesParamsTotalWithoutFilters
} from 'services/files/selectors';
import { getEntitlementsHasFilesAccess } from 'services/auth/selectors';
import {
  resetFilesFilters,
  setFilesFilters,
  setFilesPage,
  setFilesPageSize
} from 'services/files/reducers';

// Misc
import format from 'date-fns/format';
import _isEmpty from 'lodash/isEmpty';
import { EbDispatch } from 'store';
import { FileListItem as IFileListItem } from 'services/files/types';
import { getFileLabel } from 'utils/statuses';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Filter } from 'components/Filter';
import { FILTER_OPTIONS } from '../Files/data';
import { useFilter } from 'hooks/useFilterToggle';
import { useSearchButton } from 'hooks/useSearchButton';
import { useEffectDidUpdate } from '../../hooks/useEffectDidUpdate';
import { Paragraph } from '../../components/Paragraph';
import { Search } from '../../components/Search';

// Const
const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';

function FileManagement() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const navigate = useNavigate();
  const { companyCode } = useParams();

  // Hooks - state
  const [filtersCount, setFiltersCount] = React.useState(0);
  const [searchText, setSearchText] = React.useState('');

  //Hooks - custom
  const { searchButton, isSearchInputActive } = useSearchButton();
  const { filterButton, isFilterOpen, toggleFilter } = useFilter(filtersCount);

  // Hooks - selectors
  const userHasFilesAccess = useSelector(getEntitlementsHasFilesAccess);
  const files = useSelector(getFilesList);
  const filterParams = useSelector(getFilesParams);
  const filesTotalWithoutFilters = useSelector(getFilesParamsTotalWithoutFilters);
  const totalFilesSearch = useSelector(getFilesParamsTotal);

  // Hooks - effects
  React.useEffect(() => {
    if (!userHasFilesAccess) navigate('/');
  }, [userHasFilesAccess, navigate]);

  React.useEffect(
    () => () => {
      dispatch(resetFilesFilters());
    },
    [dispatch]
  );

  useEffectDidUpdate(() => {
    if (!isSearchInputActive) {
      setSearchText('');
      void dispatch(setFilesFilters({ search: ''}));
      void dispatch(doListFiles());
    }
  }, [isSearchInputActive, dispatch]);

  const suggestions = (
    <>
      <Paragraph>Suggestions:</Paragraph>
      <Paragraph>Make sure that file name is spelled correctly.</Paragraph>
      <Paragraph>Try different file name.</Paragraph>
    </>
  );

  // Handler
  const onSearch = async (text: string) => {
    const search = text?.toLowerCase();
    setSearchText(search);
    const {statusColor, uploadedAt} = filterParams;
    dispatch(setFilesFilters({ status: statusColor, uploadedAt, search }));
    await dispatch(doListFiles());
  };

  const onSearchCancel = () => {
    setSearchText('');
    void dispatch(setFilesFilters({ search: ''}));
    void dispatch(doListFiles());
  };

  const handleFilter = (filters: Record<string, string>) => {
    setFiltersCount(Object.keys(filters).length);

    if (_isEmpty(filters)) {
      dispatch(resetFilesFilters());
      dispatch(setFilesFilters({ search: searchText }));
    } else dispatch(setFilesFilters({ ...filters, search: searchText }));

    void dispatch(doListFiles());
    toggleFilter();
  };

  // Render
  const renderHeading = () => (
    <div className="eb-file_management-heading">
      <Heading size={32} type="h1">
        File Management
      </Heading>
      <div className="eb-file_management-heading-filter-buttons">
        {searchButton}
        {filesTotalWithoutFilters > 0 && filterButton}
      </div>
    </div>
  );

  const renderFile = (file: IFileListItem) => {
    const uploadedBy = file.submittedBy
      ? ` by ${file.submittedBy.username ?? file.submittedBy.username ?? ''}`
      : '';
    const uploadedDate = format(new Date(file.uploadedAt), DATE_FORMAT);
    const successCount = file?.successCount ?? 0;
    const failureCount = file?.failureCount ?? 0;
    const noActionCount = file?.noActionCount ?? 0;
    const updatedCount = file?.updatedCount ?? 0;

    const totalCount = successCount + failureCount + (noActionCount ?? 0) + (updatedCount ?? 0);
    const footer = (
      <div className="eb-file_management-file-info">
        <div className="eb-file_management-file-info-row">
          <span
            className={file.name?.startsWith('test_') ? 'eb-file_management-file-info-test' : ''}
          >
            {getFileLabel(file.name)}
          </span>
          {uploadedDate && (
            <>
              <Dot color="gray" />
              <div className="eb-file_management-file-info-uploaded">
                {uploadedDate}
                {uploadedBy}
              </div>
            </>
          )}
          <div className="file-id">
            File ID: <strong>{file.id}</strong>
          </div>
        </div>

        <div className="eb-file_management-summary-counts">
          <div className="eb-file_management-summary-counts-item">
            <span>Total</span>
            <span>{totalCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>Accepted</span>
            <span>{successCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>Rejected</span>
            <span>{failureCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>No action</span>
            <span>{noActionCount}</span>
          </div>
          <div className="eb-file_management-summary-counts-item">
            <span>Updated</span>
            <span>{updatedCount}</span>
          </div>
        </div>
      </div>
    );

    return (
      <FileListItem
        author={uploadedBy}
        detailsUrl={`/${companyCode}/file-management/${file.id}`}
        errorsCount={file.failureCount}
        fileName={file.name}
        footer={footer}
        icon="file"
        key={file.id}
        status={file.status}
        updated={uploadedDate}
      />
    );
  };

  const renderChildren = () => {
    const empty = <NoFiles fileUploadType={FileUploadType.ENROLLMENT} />;

    return (
      <TableList
        empty={empty}
        loadingText="Loading..."
        requests={[doListFiles]}
        selectorItemsTotal={getFilesParamsTotal}
        selectorPage={getFilesParamsPage}
        selectorPageSize={getFilesParamsPageSize}
        selectorPagesTotal={getFilesParamsTotalPages}
        setPage={(page) => dispatch(setFilesPage(page))}
        setPageSize={(pageSize) => dispatch(setFilesPageSize(pageSize))}
        title="File History"
      >
        {files.map((file) => renderFile(file))}
      </TableList>
    );
  };

  return (
    <LayoutPage className="eb-file_management" heading={renderHeading()}>
      <Search
        isVisible={isSearchInputActive}
        onCancel={onSearchCancel}
        onSubmit={onSearch}
        placeHolder="Search by file name"
        request={doListFiles}
        suggestions={suggestions}
        totalResultsFound={totalFilesSearch}
      />
      <Filter
        isVisible={isFilterOpen}
        onApply={handleFilter}
        onCancel={toggleFilter}
        options={FILTER_OPTIONS}
        request={doListFiles}
      />

      {renderChildren()}
    </LayoutPage>
  );
}

export { FileManagement };
