// Global
import './styles.scss';
import React from 'react';

// Components
import { Button } from 'components/Button';
import { Callout } from 'components/Callout';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Input } from 'components/Input';
import { Modal } from 'components/Modal';
import { ModalBodyAction } from 'components/Modal/BodyAction';
import { Paragraph } from 'components/Paragraph';

// Services
import { doGetAdminUsers } from 'services/adminUsers/async';
import { doInviteUsers } from 'services/adminUsers/async';
import { getEndpointError, getEndpointPending, getEndpointSuccess } from 'services/api/selectors';
import {
  getEntitlementEbPortalCompanyCode,
  getEntitlementsIsHrAdmin
} from 'services/auth/selectors';
import { getOrganizationsSelected } from 'services/organizations/selectors';

// Misc
import { EbDispatch, EbState } from 'store';
import { InviteUserFormValues, ModalInviteUserProps } from './types';
import { InputProps } from 'components/Input/types';
import { schema } from './schema';
import { useDispatch, useSelector } from 'react-redux';

// Constants
const HR_ADMIN_ROLES = [
  { label: 'Admin', value: 'hr_admin' },
  { label: 'Super Admin', value: 'super_admin' },
  { label: 'Developer', value: 'developer' }

];
const AURA_ADMIN_ROLES = [
  { label: 'Admin', value: 'aura_admin' },
  { label: 'Super Admin', value: 'super_admin' },
  { label: 'Developer', value: 'developer' }
];

const ModalInviteUser: React.FC<ModalInviteUserProps> = ({ isVisible, hide, withinCompany }) => {
  const dispatch: EbDispatch = useDispatch();

  // Hooks - state
  const [formValues, setFormValues] = React.useState({});

  // Selectors
  const isHrAdmin = useSelector(getEntitlementsIsHrAdmin);

  const isSuccess = useSelector((state: EbState) => getEndpointSuccess(state, doInviteUsers));
  const isPending = useSelector((state: EbState) => getEndpointPending(state, doInviteUsers));
  const isError = useSelector((state: EbState) => getEndpointError(state, doInviteUsers));
  const userCompanyCodes = useSelector(getEntitlementEbPortalCompanyCode);
  const selectedCompanyCode = useSelector(getOrganizationsSelected);

  // Vars
  const isSubmitDisabled = Object.values(formValues).every((val) => !val) ?? isPending;
  let companyCode = selectedCompanyCode ? selectedCompanyCode : userCompanyCodes[0] || '';

  // Handlers
  const handleSubmit = async (values: InviteUserFormValues) => {
    let role = values.role;
    if (values.role === 'super_admin') {
      role = isHrAdmin ? 'hr_admin' : withinCompany ? 'hr_admin' : 'aura_admin';
    }
    const data = {
      name: {
        first: values.firstName,
        last: values.lastName
      },
      email: values.email,
      role,
      super: values.role === 'super_admin',
      companyCodes: [companyCode]
    };
    await dispatch(doInviteUsers(data));
  };

  const handleClose = React.useCallback(() => {
    if (isSuccess) {
      hide(false);
      void dispatch(doGetAdminUsers());
    } else {
      hide(!isSubmitDisabled);
    }
  }, [isSubmitDisabled, hide, isSuccess, dispatch]);

  // Render
  const renderUserField = (label: string, name: string, inputProps?: Partial<InputProps>) => {
    return (
      <div className="eb-modal_invite_user-field">
        <Paragraph className="eb-modal_invite_user-field-label">{label}</Paragraph>
        <Input
          className="eb-modal_invite_user-field-input"
          name={name}
          onChange={(e) =>
            setFormValues({ ...formValues, [name]: (e.target as HTMLInputElement).value })
          }
          type="text"
          {...inputProps}
        />
      </div>
    );
  };

  return (
    <Modal
      className="eb-modal_invite_user"
      hide={handleClose}
      isSuccess={isSuccess}
      isVisible={isVisible}
      successToastMessage="Admin invited"
    >
      <ModalBodyAction
        actionBarButtonCancel={
          <Button intent="secondary" onClick={handleClose}>
            Cancel
          </Button>
        }
        actionBarButtonSave={
          <Button
            form="eb-modal_invite_user-form"
            isDisabled={isSubmitDisabled}
            isLoading={isPending}
            type="submit"
          >
            Invite
          </Button>
        }
      >
        <Heading className="eb-modal_invite_user-heading" size={26} type="h4">
          Invite {withinCompany ? 'HR' : 'Aura'} admin
        </Heading>
        <Callout className="eb-modal_invite_user-callout" icon="info">
          <Heading size={14} type="h6">
            Please ensure the person you add has permission to access private information within
            your organization.
          </Heading>
        </Callout>
        <Form
          error={isError}
          id="eb-modal_invite_user-form"
          name="eb-modal_invite_user-form"
          onSubmit={handleSubmit}
          pending={isPending}
          schema={schema}
        >
          {renderUserField('First name', 'firstName')}
          {renderUserField('Last name', 'lastName')}
          {renderUserField('Email address', 'email')}
          {renderUserField('Role', 'role', {
            options: withinCompany ? HR_ADMIN_ROLES : AURA_ADMIN_ROLES,
            type: 'select'
          })}
        </Form>
      </ModalBodyAction>
    </Modal>
  );
};

export { ModalInviteUser };
