import { FileStatus, FileSubmittedBy } from 'services/files/types';

export enum FileDetailsActionTypes {
  SET_FILE_DETAILS = 'eb.file_details.SET_FILE_DETAILS',
  SET_PAGE = 'eb.file_details.SET_PAGE',
  SET_PAGE_SIZE = 'eb.file_details.SET_PAGE_SIZE',
  SET_SORT_FIELD = 'eb.file_details.SET_SORT_FIELD',
  SET_SORT_ORDER = 'eb.file_details.SET_SORT_ORDER',
  SET_COUNT = 'eb.file_details.SET_COUNT',
  RESET_FILTERS = 'eb.file_details.RESET_FILTERS'
}

// Generic FileDetails Action
export interface FileDetailsAction<P> {
  type: FileDetailsActionTypes;
  data: P;
}

export interface FileRecord {
  action: string;
  companyUserId: string;
  companyCode: string;
  name: {
    first: string;
    middle: string;
    last: string;
    suffix: string;
  };
  recordStatus: string;
  failureReason: string;
  rowNumber: string;
  userId: string;
}

export type FileSummary = {
  fileName: string;
  fileStatus: FileStatus | null;
  fileUploadedAt: string;
  failureReason?: string;
  submittedBy?: FileSubmittedBy;
  errorReportRequested?: boolean;
  errorReportAvailable?: boolean;
};

export interface GetFileDetailsResponse {
  pageSize: number;
  page: number;
  sortField: string;
  sortOrder: 1 | -1;
  total: number;
  totalPages: number;
  acceptedRecordCount: number;
  rejectedRecordCount: number;
  noActionRecordCount: number;
  updatedRecordCount: number;
  records: FileRecord[];
  summary: FileSummary;
}

export interface FileDetailsSetAction extends FileDetailsAction<GetFileDetailsResponse> {
  type: FileDetailsActionTypes.SET_FILE_DETAILS;
}

export interface FileDetailsSetCount extends FileDetailsAction<number> {
  type: FileDetailsActionTypes.SET_COUNT;
}

export interface FileDetailsSetPageAction extends FileDetailsAction<number> {
  type: FileDetailsActionTypes.SET_PAGE;
}

export interface FileDetailsSetPageSizeAction extends FileDetailsAction<number> {
  type: FileDetailsActionTypes.SET_PAGE_SIZE;
}

export interface FileDetailsSetSortFieldAction extends FileDetailsAction<string> {
  type: FileDetailsActionTypes.SET_SORT_FIELD;
}

export interface FileDetailsSetSortOrderAction extends FileDetailsAction<1 | -1> {
  type: FileDetailsActionTypes.SET_SORT_ORDER;
}

export interface FileDetailsResetFiltersAction extends FileDetailsAction<null> {
  type: FileDetailsActionTypes.RESET_FILTERS;
}

export type FileDetailsAllAction =
  | FileDetailsSetAction
  | FileDetailsSetPageAction
  | FileDetailsSetPageSizeAction
  | FileDetailsSetSortFieldAction
  | FileDetailsSetSortOrderAction
  | FileDetailsSetCount
  | FileDetailsResetFiltersAction;

export interface FileDetailsParamState {
  page: number;
  pageSize: number;
  sortField: string;
  sortOrder: 1 | -1;
  totalPages: number;
  total: number;
}

export interface FileDetailsRequestParams {
  page: number;
  pageSize: number;
  sortField?: string;
  sortOrder?: 1 | -1;
}

export interface FileRecordsState {
  list: FileRecord[];
  params: FileDetailsParamState;
  acceptedRecordCount: number;
  rejectedRecordCount: number;
  noActionRecordCount: number;
  updatedRecordCount: number;
}

export interface FileDetailsState {
  records: FileRecordsState;
  summary: FileSummary;
}
