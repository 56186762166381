export enum PeopleActionTypes {
  SET_PEOPLE = 'eb.people.SET_PEOPLE',
  UPDATE_PEOPLE = 'eb.people.UPDATE_PEOPLE',
  SET_PAGE = 'eb.people.SET_PAGE',
  SET_PAGE_SIZE = 'eb.people.SET_PAGE_SIZE',
  SET_SEARCH = 'eb.people.SET_SEARCH',
  SET_FILTER = 'eb.people.SET_FILTER',
  SET_COUNT = 'eb.people.SET_COUNT',
  SET_CURRENT_COMPANY = 'eb.people.SET_CURRENT_COMPANY',
  RESET_FILTERS = 'eb.people.RESET_FILTERS',
  SET_MEMBER_DETAILS = 'eb.people.SET_MEMBER_DETAILS',
  CLEAR_MEMBER_DETAILS = 'eb.people.CLEAR_MEMBER_DETAILS',
  DELETE_MEMBER = 'eb.people.DELETE_MEMBER'
}

// Generic People Action
export interface PeopleAction<P> {
  type: PeopleActionTypes;
  data: P;
}

export enum SubscriptionStatus {
  PENDING = 'PENDING',
  PROVISIONING = 'PROVISIONING',
  CLOSED = 'CLOSED',
  FAILED = 'FAILED',
  CONVERTED = 'CONVERTED',
  RECORD_FAILURE = 'record_failure',
  PENDING_ENROLLMENT = 'pending_enrollment',
  PENDING_REQUEST = 'pending_request',
  ELIGIBLE = 'eligible',
  ENROLLED = 'enrolled',
  EXPIRED = 'expired',
  TERMINATED = 'terminated',
  PORTED = 'ported',
  ACTIVE = 'ACTIVE',
  NULL = 'NULL'
}

export enum BulkActionStatus {
  PROCESSING = 'processing',
  ERROR = 'error',
  SUCCESS = 'completed'
}

export enum MemberAction {
  TERM = 'term',
  PORT = 'port'
}

export interface Name {
  first: string;
  middle?: string;
  last: string;
  suffix?: string;
}

export interface CompanyPeopleRecord {
  companyUserId: string;
  companyCode: string;
  name: Name;
  plan: string;
  status: SubscriptionStatus;
  planCode: string;
  planTitle: string;
  updatedAt: string;
  updated: string;
  userId: string;
  // Appears in the record after term/port bulk action
  bulkAction?: {
    state: BulkActionStatus | null;
    action: MemberAction;
    eventId?: string;
  };
  scheduledEffectiveDate: string;
  scheduledChangeType?: ScheduledChangeType;
}

export interface GetCompanyPeopleResponse {
  pageSize?: number;
  page?: number;
  total?: number;
  totalPages?: number;
  members: CompanyPeopleRecord[];
  eligibilityUserCount: { count: number };
  enrolledUserCount: { count: number };
}

export enum ScheduledChangeType {
  CHANGE = 'CHANGE',
  ADD = 'ADD',
  TERMINATE = 'TERMINATE'
}

export interface MemberDetails {
  birthDate: string;
  companyCode: string;
  companyUserId: string;
  deptId: string;
  emailAddress: string;
  employeeId: string;
  name: Name;
  proxyOrgCode: string | null;
  userId: string;
  planCode: string;
  planTitle: string;
  status: SubscriptionStatus;
  updatedAt: string;
  scheduledEffectiveDate?: string;
  scheduledChangeType?: ScheduledChangeType;
}

export interface PeopleSetAction extends PeopleAction<GetCompanyPeopleResponse> {
  type: PeopleActionTypes.SET_PEOPLE;
}

export interface PeopleUpdateAction extends PeopleAction<CompanyPeopleRecord[]> {
  type: PeopleActionTypes.UPDATE_PEOPLE;
}

export interface MemberDetailsSetAction extends PeopleAction<MemberDetails> {
  type: PeopleActionTypes.SET_MEMBER_DETAILS;
}

export interface MemberDetailsClearAction extends PeopleAction<null> {
  type: PeopleActionTypes.CLEAR_MEMBER_DETAILS;
}

export interface PeopleSetCount extends PeopleAction<number> {
  type: PeopleActionTypes.SET_COUNT;
}

export interface PeopleSetPageAction extends PeopleAction<number> {
  type: PeopleActionTypes.SET_PAGE;
}

export interface PeopleSetPageSizeAction extends PeopleAction<number> {
  type: PeopleActionTypes.SET_PAGE_SIZE;
}

export interface PeopleSetSearchAction extends PeopleAction<string> {
  type: PeopleActionTypes.SET_SEARCH;
}

export interface PeopleSetFilterAction extends PeopleAction<any> {
  type: PeopleActionTypes.SET_FILTER;
}

export interface PeopleResetFiltersAction extends PeopleAction<null> {
  type: PeopleActionTypes.RESET_FILTERS;
}

export interface PeopleDeleteMemberAction extends PeopleAction<null> {
  type: PeopleActionTypes.DELETE_MEMBER;
}

export type PeopleAllAction =
  | PeopleSetAction
  | PeopleUpdateAction
  | PeopleSetPageAction
  | PeopleSetPageSizeAction
  | PeopleSetCount
  | PeopleResetFiltersAction
  | MemberDetailsSetAction
  | MemberDetailsClearAction
  | PeopleSetSearchAction
  | PeopleSetFilterAction
  | PeopleDeleteMemberAction;

export interface PeopleFilters {
  plan?: string;
  status?: string;
}

export interface PeopleParamState {
  page: number;
  pageSize: number;
  totalPages: number;
  total: number;
  search?: string;
  totalWithoutFilters: number;
  filters?: PeopleFilters;
}

export interface PeopleRequestParams {
  page: number;
  pageSize: number;
  search?: string;
}

export interface PeopleState {
  eligibilityUserCount: number;
  enrolledUserCount: number;
  list: CompanyPeopleRecord[];
  params: PeopleParamState;
  memberDetails: MemberDetails;
}
