// Global
import React from 'react';
import './styles.scss';

// Components
import { Anchor } from 'components/Anchor';
import { AriaButton } from 'components/AriaButton';
import { Badge } from 'components/Badge';
import { Callout } from 'components/Callout';
import { Dot } from 'components/Dot';
import { DropdownFilter } from 'components/DropdownFilter';
import { TagStatus } from 'components/TagStatus';

// Services
import { doDownloadFile, requestResponseFile } from 'services/download/async';
import { setToastError, setToastSuccess } from 'services/toast/reducers';
import { doUpdateFileMetadata } from 'services/files/async';

// Misc
import clsx from 'clsx';
import { FilesListItemProps } from './types';
import { getFileStatusDisplayName, getFileStatusTag } from 'utils/statuses';
import { isFileClickable } from './util';
import { format, formatRelative } from 'date-fns';
import { EbDispatch } from 'store';
import { useDispatch } from 'react-redux';
import { FileReviewStatus } from 'services/files/types';
import { DropdownEvent } from 'components/DropdownFilter/types';

// Const
const DATE_FORMAT = 'MMM d, yyyy, hh:mm a';

const FILE_REVIEW_OPTIONS = [
  { label: 'Not reviewed', value: FileReviewStatus.NOT_REVIEWED },
  { label: 'In progress', value: FileReviewStatus.IN_PROGRESS },
  { label: 'Reviewed / Addressed', value: FileReviewStatus.REVIEWED }
];

const FilesListItem: React.FC<FilesListItemProps> = ({
  id,
  name,
  className,
  status,
  uploadedAt,
  submittedBy,
  failureReason,
  errorReportUri,
  reviewedAt = '',
  reviewStatus,
  failureCount = 0,
  noActionCount = 0,
  updatedCount = 0,
  uploadedCompanyCode,
  companyCodes,
  totalCount = 0,
  successCount = 0,
  addRequestCount = 0,
  termRequestCount = 0
}) => {
  const dispatch: EbDispatch = useDispatch();

  // Vars
  const classes = clsx('eb-files_list_item', className);
  const tagStatus = getFileStatusTag(status, failureCount);
  const fileStatusName = getFileStatusDisplayName(status);
  const isClickable = isFileClickable(tagStatus, uploadedAt);
  const uploadedBy = submittedBy ? ` by ${submittedBy.username ?? submittedBy.username ?? ''}` : '';
  const uploadedDate = uploadedAt ? format(new Date(uploadedAt), DATE_FORMAT) : '';
  const companyCode = companyCodes?.[0] ?? uploadedCompanyCode;

  const generateErrorReport = React.useCallback(async () => {
    if (id && errorReportUri) {
      await dispatch(doDownloadFile(id));
      dispatch(setToastSuccess('Downloading error report.'));
    } else if (id) {
      await dispatch(requestResponseFile(id, companyCode));
      dispatch(setToastSuccess('Requesting error report. You should recieve an email shortly.'));
    }
  }, [id, dispatch, errorReportUri, companyCode]);

  const handleChangeReviewStatus = async (event?: DropdownEvent) => {
    try {
      await dispatch(
        doUpdateFileMetadata({
          fileId: id,
          reviewStatus:
            (event?.dropdownFilter.value as FileReviewStatus) || FileReviewStatus.NOT_REVIEWED
        })
      );
      dispatch(setToastSuccess('File review status has been updated.'));
    } catch (_) {
      setToastError('An error occurred while updating file review status.');
    }
  };

  return (
    <div className={classes}>
      <div className="">
        <div className="eb-files_list_item-grid-row">
          <div className="eb-files_list_item-grid-row eb-files_list_item-row-space-between">
            {isClickable ? (
              <Anchor href={`/${companyCode}/file-management/${id}`} intent="primary">
                {name ? name : 'File name not available'}
              </Anchor>
            ) : (
              <div className="eb-files_list_item-info-heading">{name ? name : 'File name not available'}</div>
            )}
            <div className="eb-files_list_item-align-right">
              {fileStatusName && (
                <TagStatus className="eb-files_list_item-status" status={tagStatus}>
                  {fileStatusName}
                </TagStatus>
              )}

              {failureCount > 0 && (
                <AriaButton
                  className="eb-files_list_item-cta"
                  onClick={generateErrorReport}
                  type="primary"
                >
                  {errorReportUri ? 'Download Report' : 'Request Report'}
                </AriaButton>
              )}
            </div>
          </div>
          {reviewStatus === FileReviewStatus.REVIEWED && reviewedAt ? (
            <Badge className="eb-files_list_item-summary-review">
              Reviewed {formatRelative(new Date(reviewedAt), new Date())}
            </Badge>
          ) : (
            <DropdownFilter
              onChange={handleChangeReviewStatus}
              options={FILE_REVIEW_OPTIONS}
              value={reviewStatus ?? FileReviewStatus.NOT_REVIEWED}
            />
          )}
        </div>
        <div className="eb-files_list_item-row">
          <Anchor href={`/${companyCode}/dashboard`} intent="primary">
            {companyCode}
          </Anchor>
          {uploadedAt && (
            <>
              <Dot color="gray" />
              <div>
                {uploadedDate}
                {uploadedBy}
              </div>
            </>
          )}
        </div>
        <div className="eb-files_list_item-row file-id">File ID: <strong>{id}</strong></div>

        <div className="eb-files_list_item-summary">
          <div className="eb-files_list_item-summary-counts">
            <div className="eb-files_list_item-summary-counts-item">
              <span>Total</span>
              <span>{totalCount}</span>
            </div>
            <div className="eb-files_list_item-summary-counts-item">
              <span>Accepted</span>
              <span>{successCount}</span>
            </div>
            <div className="eb-files_list_item-summary-counts-item">
              <span>Rejected</span>
              <span>{failureCount}</span>
            </div>
            <div className="eb-files_list_item-summary-counts-item">
              <span>No action</span>
              <span>{noActionCount}</span>
            </div>
            <div className="eb-files_list_item-summary-counts-item">
              <span>Updated</span>
              <span>{updatedCount}</span>
            </div>
          </div>
        </div>
        <div className="eb-files_list_item-summary-counts">
          <div className="eb-files_list_item-summary-counts-item">
            <span>ADD</span>
            <span>{addRequestCount}</span>
          </div>
          <div className="eb-files_list_item-summary-counts-item">
            <span>TERM</span>
            <span>{termRequestCount}</span>
          </div>
        </div>
      </div>

      {failureReason && (
        <Callout className="eb-files_list_item-error-details" status="warning">
          {failureReason}
        </Callout>
      )}
    </div>
  );
};

export { FilesListItem };
