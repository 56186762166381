// Global
import './styles.scss';
import React from 'react';

// Components
import { AriaButton } from 'components/AriaButton';
import { Callout } from 'components/Callout';
import { Dot } from 'components/Dot';
import { ErrorServer } from 'components/ErrorServer';
import { FileDetailsError } from './Error';
import { FileDetailsRecordErrors } from './RecordErrors';
import { Heading } from 'components/Heading';
import { LayoutPage } from 'components/LayoutPage';
import { Loading } from 'components/Loading';
import { Paragraph } from 'components/Paragraph';
import { SummaryCard } from 'components/SummaryCard';
import { Table } from 'components/Table';
import { TagStatus } from 'components/TagStatus';
import { Toast } from 'components/Toast';

// Services
import { doDownloadFile, requestResponseFile } from 'services/download/async';
import { doGetFileById } from 'services/fileDetails/async';
import { getEndpointFailure, getEndpointPending } from 'services/api/selectors';
import { getEntitlementsHasFilesAccess } from 'services/auth/selectors';
import {
  getFileDetailsParams,
  getFileDetailsRecords,
  getFileDetailsRecordsList,
  getFileDetailsSummary
} from 'services/fileDetails/selectors';
import {
  resetFilters,
  setPage,
  setPageSize,
  setSortField,
  setSortOrder
} from 'services/fileDetails/reducers';
import { setToastError, setToastSuccess } from 'services/toast/reducers';

// Misc
import format from 'date-fns/format';
import { DATE_FORMAT, TABLE_COLUMNS } from './data';
import { EbDispatch, EbState } from 'store';
import { FileStatus } from 'services/files/types';
import { getFileLabel, getFileStatusDisplayName, getFileStatusTag } from 'utils/statuses';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

const EXPANDED_ROW_CLASSNAME = 'eb-file_details-row-expanded';

function FileDetails() {
  // Hooks
  const dispatch: EbDispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  // Hooks - state
  const [isSortingPending, setIsSortingPending] = React.useState(false);

  // Hooks - selectors
  const userHasFilesAccess = useSelector(getEntitlementsHasFilesAccess);
  const isPending = useSelector((state: EbState) => getEndpointPending(state, doGetFileById));
  const isServerError = useSelector((state: EbState) => getEndpointFailure(state, doGetFileById));
  const isErrorReportError = useSelector((state: EbState) =>
    getEndpointFailure(state, requestResponseFile)
  );
  const fileRecords = useSelector(getFileDetailsRecordsList);
  const { page, pageSize, totalPages, total, sortField, sortOrder } =
    useSelector(getFileDetailsParams);
  const {
    fileName = '',
    fileStatus = null,
    fileUploadedAt = '',
    failureReason = '',
    submittedBy
  } = useSelector(getFileDetailsSummary);
  const { acceptedRecordCount, rejectedRecordCount, noActionRecordCount, updatedRecordCount } =
    useSelector(getFileDetailsRecords);
  const fileUploadedDate = fileUploadedAt ? format(new Date(fileUploadedAt), DATE_FORMAT) : '';

  // Handlers
  const getFileDetails = React.useCallback(() => {
    if (params.fileId && userHasFilesAccess) void dispatch(doGetFileById(params.fileId));
  }, [userHasFilesAccess, params?.fileId, dispatch]);

  const generateErrorReport = React.useCallback(async () => {
    try {
      if (params.fileId) {
        await dispatch(doDownloadFile(params.fileId));
        dispatch(setToastSuccess('Downloading error report.'));
      }
    } catch (e) {
      dispatch(
        setToastSuccess(
          'Something went wrong while downloading error report... Please try again later.'
        )
      );
    }
  }, [params?.fileId, dispatch]);

  const changePage = React.useCallback(
    (page: number) => {
      dispatch(setPage(page));
      getFileDetails();
    },
    [getFileDetails, dispatch]
  );

  const changePageSize = React.useCallback(
    (pageSize: number) => {
      dispatch(setPageSize(pageSize));
      getFileDetails();
    },
    [getFileDetails, dispatch]
  );

  const setSorting = React.useCallback(
    (field: string) => {
      setIsSortingPending(true);
      dispatch(setSortField(field));
      dispatch(setSortOrder(sortOrder === 1 ? -1 : 1));
      getFileDetails();
    },
    [getFileDetails, sortOrder, dispatch]
  );

  React.useEffect(() => {
    if (!isPending) setIsSortingPending(false);
  }, [isPending]);

  const handleExpandRowClick = React.useCallback((collapsed: boolean, e: React.MouseEvent) => {
    const parentRow = (e.target as HTMLElement).closest('.eb-table-row');
    if (collapsed) {
      parentRow?.classList.remove(EXPANDED_ROW_CLASSNAME);
    } else {
      parentRow?.classList.add(EXPANDED_ROW_CLASSNAME);
    }
  }, []);

  // Hooks - effects
  React.useEffect(() => {
    if (!userHasFilesAccess) navigate('/');
  }, [userHasFilesAccess, navigate]);

  React.useEffect(() => {
    getFileDetails();
  }, [getFileDetails]);

  React.useEffect(() => {
    if (isErrorReportError) {
      dispatch(setToastError('An error occurred. Please try again later.'));
    }
  }, [dispatch, isErrorReportError]);

  React.useEffect(
    () => () => {
      dispatch(resetFilters());
    },
    [dispatch]
  );

  // Render
  const renderHeading = () => {
    if (isPending || isServerError) return null;

    const tagStatus = getFileStatusTag(fileStatus, rejectedRecordCount);
    const fileStatusName = getFileStatusDisplayName(fileStatus, 'File');
    const uploadedBy = submittedBy
      ? ` by ${submittedBy.username ?? submittedBy.username ?? ''}`
      : '';

    return (
      <div className="eb-file_details-heading">
        <div className="eb-file_details-heading-container">
          <Heading className="eb-file_details-heading-text" size={32} type="h1">
            {fileName}
          </Heading>
          <TagStatus className="eb-file_details-heading-tag" status={tagStatus}>
            {fileStatusName}
          </TagStatus>
        </div>
        <span className="eb-file_details-heading-subheading">
          {getFileLabel(fileName)}
          <Dot color="gray" />
          Uploaded {fileUploadedDate}
          {uploadedBy}
        </span>
      </div>
    );
  };

  const renderChildren = () => {
    if (isPending && !isSortingPending) return <Loading>Loading...</Loading>;
    if (isServerError) return <ErrorServer />;

    const rejectedRowsIds = fileRecords
      .filter(({ failureReason }) => Boolean(failureReason))
      .map(({ rowNumber }) => rowNumber);

    switch (fileStatus) {
      case null:
        return <Loading>Processing file id: {params.fileId}...</Loading>;
      case FileStatus.REJECTED:
        return <FileDetailsError errorMessage={failureReason} />;
      default: {
        return (
          <>
            {rejectedRecordCount > 0 && (
              <Callout className="eb-file_details-callout" icon="info">
                <Paragraph>
                  Some records <strong>contain errors</strong> and could not be processed. Download
                  the Error Report now, then correct the unprocessed records in a new file.
                </Paragraph>
              </Callout>
            )}
            <div className="eb-file_details-summary">
              <SummaryCard icon="user_group" title="Total people" total={total} />
              <SummaryCard icon="double_check" title="Accepted" total={acceptedRecordCount} />
              <SummaryCard
                button={
                  rejectedRecordCount > 0 ? (
                    <AriaButton
                      className="eb-file_details-summary-btn"
                      onClick={generateErrorReport}
                      type="primary"
                    >
                      Download Report
                    </AriaButton>
                  ) : null
                }
                icon="attention"
                title="Rejected"
                total={rejectedRecordCount}
              />
              <SummaryCard icon="user_unknown" title="No action" total={noActionRecordCount} />
              <SummaryCard icon="document_add" title="Updated" total={updatedRecordCount} />

            </div>

            <Table
              activeSortKey={sortField}
              className="eb-file_details-table"
              columns={TABLE_COLUMNS}
              data={fileRecords}
              expandable={{
                expandedRowRender: ({ failureReason }) => (
                  <FileDetailsRecordErrors error={failureReason} />
                ),
                onExpandClick: handleExpandRowClick,
                defaultExpandedRowKeys: rejectedRowsIds
              }}
              handleSort={setSorting}
              isLoading={isPending}
              itemsTotal={total}
              page={page}
              pageSize={pageSize}
              pagesTotal={totalPages}
              rowKey={'rowNumber'}
              setPage={changePage}
              setPageSize={changePageSize}
              sortKeys={['requestType', 'status', 'lineNumber']}
              sortMapping={{ action: 'requestType', rowNumber: 'lineNumber' }}
              sortOrder={sortOrder}
              title="People in File"
            />
          </>
        );
      }
    }
  };

  return (
    <LayoutPage className="eb-file_details" hasBreadcrumbs heading={renderHeading()}>
      <Toast />
      {renderChildren()}
    </LayoutPage>
  );
}

export { FileDetails };
