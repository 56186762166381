// Globals
import './styles.scss';
import React from 'react';

// Components
import { AriaButton } from 'components/AriaButton';
import { Button } from 'components/Button';
import { ErrorServer } from 'components/ErrorServer';
import { Form } from 'components/Form';
import { Heading } from 'components/Heading';
import { Icon } from 'components/Icon';
import { Input } from 'components/Input';
import { Loading } from 'components/Loading';
import { Modal } from 'components/Modal';
import { ModalBodyAction } from 'components/Modal/BodyAction';
import { Paragraph } from 'components/Paragraph';

// Services
import {
  doBulkActionMembers,
  doDeleteMember,
  doGetCurrentCompanyPeople,
  doGetMemberDetails
} from 'services/people/async';
import {
  getEndpointError,
  getEndpointFailure,
  getEndpointPending,
} from 'services/api/selectors';
import { getMemberDetails } from 'services/people/selectors';
import { clearErrors } from 'services/api/async';
import { setToastSuccess } from 'services/toast/reducers';
import { MemberAction, SubscriptionStatus } from 'services/people/types';

// Misc
import { formatBirthDate, formatDate } from 'utils/formatDate';
import { getMemberDisplayName } from 'utils/statuses';
import { useDispatch, useSelector } from 'react-redux';
import { schema } from './schema';

// Types
import { EbDispatch, EbState } from 'store';
import { MemberDetailsFormData, MemberRowProps, ModalUploadFileProps } from './types';
import { getEntitlementEbUserAccessLevel } from '../../services/auth/selectors';
import { AccessLevel } from '../../services/auth/types';
import {
  getOrganizationCompanyCode,
} from '../../services/organizations/selectors';
import { doUpdatePartnerExternalId } from '../../services/partnerExternal/async';

const ACTIVE_STATUSES = [
  SubscriptionStatus.PENDING_REQUEST,
  SubscriptionStatus.ELIGIBLE,
  SubscriptionStatus.ENROLLED,
  SubscriptionStatus.PORTED,
  SubscriptionStatus.ACTIVE
];

const MemberRow: React.FC<MemberRowProps> = ({ label, name, isEditable }) => {
  return (
    <div className="eb-modal_member_details_member_row">
      <Paragraph>{label}</Paragraph>
      <Input name={name} readOnly={!isEditable} type="text" />
    </div>
  );
};

const ModalMemberDetails: React.FC<ModalUploadFileProps> = ({
  hide,
  isVisible,
  onTermOrPortClick
}) => {
  // Hooks
  const dispatch: EbDispatch = useDispatch();

  // Hooks - Selectors
  const isPending = useSelector((state: EbState) => getEndpointPending(state, doGetMemberDetails));
  const isPendingTerm = useSelector((state: EbState) =>
    getEndpointPending(state, doBulkActionMembers)
  );
  const isError = useSelector((state: EbState) => getEndpointFailure(state, doGetMemberDetails));
  const saveError = useSelector((state: EbState) =>
    getEndpointError(state, doUpdatePartnerExternalId)
  );
  const isSavePending = useSelector((state: EbState) =>
    getEndpointPending(state, doUpdatePartnerExternalId)
  );
  const currentUserAccessLevel = useSelector(getEntitlementEbUserAccessLevel);
  const {
    companyUserId,
    birthDate,
    deptId,
    emailAddress,
    employeeId,
    name,
    planTitle,
    status,
    updatedAt,
    userId,
    scheduledEffectiveDate,
    scheduledChangeType
  } = useSelector(getMemberDetails);
  const companyCode = useSelector(getOrganizationCompanyCode);

  // Hooks - state
  const [deleteError, setDeleteError] = React.useState('');

  // Handlers
  const resetErrors = React.useCallback(() => {
    void dispatch(clearErrors([doUpdatePartnerExternalId, doGetMemberDetails]));
    setDeleteError('');
  }, [dispatch]);

  const onHide = React.useCallback(
    (isUpdated: boolean = false) => {
      resetErrors();
      hide(isUpdated);
    },
    [hide, resetErrors]
  );

  const onSubmit = React.useCallback(
    async ({ companyUserId: newCompanyUserId }: MemberDetailsFormData) => {
      resetErrors();
      const isNoUpdates = companyUserId === newCompanyUserId;

      if (isNoUpdates) {
        onHide(false);
      } else {
        await dispatch(
          doUpdatePartnerExternalId({
            externalId: companyUserId,
            newExternalId: newCompanyUserId,
            companyCode
          })
        );

        void dispatch(doGetCurrentCompanyPeople());
        dispatch(setToastSuccess('Member has been successfully updated.'));
        onHide(true);
      }
    },
    [onHide, resetErrors, companyUserId, companyCode, dispatch]
  );

  const handleDeleteClick = React.useCallback(async () => {
    try {
      await dispatch(doDeleteMember(userId));
      onHide(true);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error deleting member', e);
      setDeleteError('Error deleting member. Please try again.');
    }
  }, [userId, onHide, dispatch]);

  // Hooks - effects
  React.useEffect(() => () => resetErrors(), [resetErrors]);

  // Vars
  const formatSubscriptionDate = formatDate(updatedAt);
  const canBeTerminated = [SubscriptionStatus.ENROLLED, SubscriptionStatus.ACTIVE].includes(status);
  const defaultValuesAsync: MemberDetailsFormData = {
    companyUserId: companyUserId ?? 'N/A',
    deptId: deptId ?? 'N/A',
    emailAddress: emailAddress ?? 'N/A',
    employeeId: employeeId ?? 'N/A',
    formatSubscriptionDate: updatedAt ? formatSubscriptionDate : 'N/A',
    firstName: name?.first ?? 'N/A',
    lastName: name?.last ?? 'N/A',
    birthDate: formatBirthDate(birthDate) ?? 'N/A',
    planTitle: planTitle ?? 'N/A',
    status: getMemberDisplayName(status, { scheduledEffectiveDate, scheduledChangeType }) ?? 'N/A',
    userId: userId ?? 'N/A'
  };
  const formProps = {
    defaultValuesAsync,
    key: JSON.stringify(defaultValuesAsync),
    id: 'eb-modal_member_details-form',
    name: 'eb-modal_member_details-form',
    schema,
    error: saveError || deleteError
  };
  const isActionDisabled = isPending || isPendingTerm || isSavePending;
  const canDeleteUser =
    [AccessLevel.FULL, AccessLevel.HR_SUPER].includes(currentUserAccessLevel) &&
    !ACTIVE_STATUSES.includes(status);

  // Render
  const renderChildren = () => {
    switch (true) {
      case isPending:
        return <Loading />;
      case isError:
        return <ErrorServer />;
      default:
        return (
          <Form onSubmit={onSubmit} {...formProps}>
            <MemberRow label="Aura User ID" name="userId" />
            <MemberRow isEditable label="Company User ID" name="companyUserId" />
            <MemberRow label="First name" name="firstName" />
            <MemberRow label="Last name" name="lastName" />
            <MemberRow label="Birth date" name="birthDate" />
            <MemberRow label="Email address" name="emailAddress" />
            {employeeId && <MemberRow label="Employee ID" name="employeeId" />}
            {deptId && <MemberRow label="Department" name="deptId" />}
            <MemberRow label="Plan" name="planTitle" />
            <MemberRow label="Status" name="status" />
            <MemberRow label="Changed" name="formatSubscriptionDate" />
          </Form>
        );
    }
  };

  const renderActions = () => (
    <div className="eb-modal_member_details-footer">
      {canBeTerminated && (
        <>
          <AriaButton
            className="eb-modal_member_details-term-button"
            isDisabled={isActionDisabled}
            onClick={() => onTermOrPortClick(MemberAction.TERM)}
          >
            <Icon name="unlink" />
            Terminate
          </AriaButton>
          <AriaButton
            className="eb-modal_member_details-port-button"
            isDisabled={isActionDisabled}
            onClick={() => onTermOrPortClick(MemberAction.PORT)}
          >
            <Icon name="link" />
            Port to Free Trial
          </AriaButton>
        </>
      )}
      {!isPending && canDeleteUser && (
        <AriaButton
          className="eb-modal_member_details-term-button"
          isDisabled={isActionDisabled}
          onClick={handleDeleteClick}
        >
          <Icon name="delete" />
          Delete
        </AriaButton>
      )}
    </div>
  );

  return (
    <Modal className="eb-modal_member_details" hide={onHide} isVisible={isVisible}>
      <ModalBodyAction
        actionBarButtonAction={renderActions()}
        actionBarButtonSave={
          <Button
            form={formProps.id}
            isDisabled={isActionDisabled}
            isFullWidth
            isLoading={isSavePending}
            type="submit"
          >
            Save
          </Button>
        }
      >
        <Heading size={26} type="h1">
          Record
        </Heading>

        {renderChildren()}
      </ModalBodyAction>
    </Modal>
  );
};

export { ModalMemberDetails };
